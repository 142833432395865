<template>
  <div class="card card-bordered">
      <div class="card-inner">
          <a-breadcrumb style="padding-bottom: 10px;">
            <router-link :to="{name: 'reports'}"><a-breadcrumb-item>Back To Reports</a-breadcrumb-item></router-link>
            <a-breadcrumb-item>Tickets By Team</a-breadcrumb-item>
          </a-breadcrumb>
          <div class="row">
            <div class="col-3">
                    <a-select show-search allowClear option-filter-prop="children" @change="getReport()" v-model="widgetFetcher.teamId" placeholder="Please Select A Team" style="width: 100%">
                        <a-select-option v-for="t in teams" :key="t.id">
                            {{ t.text }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-9">
                    <div class="float-right">
                        <div class="btn-group" style="margin-right: 15px;">
                            <button @click="setMonth('thisMonth')" type="button" class="btn btn-primary btn-sm">This Month</button>
                            <button @click="setMonth('lastMonth')" class="btn btn-dark btn-sm">Last Month</button>
                            <button @click="setMonth('monthBeforeLast')" class="btn btn-dark btn-sm">Month <em style="font-size:10px;" class="icon ni ni-caret-left-fill"></em> Last</button>
                            <button @click="setMonth('thisYear')" class="btn btn-dark btn-sm">This Year</button>
                        </div>
                        <a-date-picker @change="getReport()" v-model="widgetFetcher.start" format="DD/MM/YYYY" placeholder="Start Date" style="margin-right: 10px;"/>
                        <a-date-picker @change="getReport()" v-model="widgetFetcher.end" format="DD/MM/YYYY" placeholder="End Date"/>
                    </div>
                </div>
            </div>
            <hr class="preview-hr">
            <div class="row">
                <div class="col-6" v-if="ticketCounts">
                    <ticketsbyteamchart :needsRefresh="needsRefresh" :widgetFetcher="widgetFetcher"/>
                </div>
                <div class="col-6" v-if="ticketCounts">
                    <div class="row">
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">Total Tickets Completed</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ ticketCounts.completedCount }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">Total Tickets Outstanding Currently</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ ticketCounts.outstandingCount }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">Total Time Spent Working On Tickets (Hours)</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ ticketCounts.timeSpentWorking}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">Average Time Spent Working Per Ticket</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ ticketCounts.avgTimeSpentPerTicket }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="preview-hr">
            <div class="col-12" v-if="tickets">
                <vue-good-table v-if="tickets"
                    :columns="columns"
                    :rows="tickets"
                    styleClass="vgt-table condensed tblhov"
                    :search-options="{ enabled: true, placeholder: 'Search Tickets'}"
                    :sort-options="{ enabled: true,}"
                    @on-cell-click="showTicket"
                >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'createdDate'">{{ $moment(props.row.createdDate).format("H:mm DD/MM/YY") }}</span>
                </template>
                </vue-good-table>
            </div>
      </div>
    <a-drawer :width="600"
      placement="right"
      :closable="false"
      @close="closeTicket()"
      :visible="showTicketData">
      <ticket :ticket="selectedTicket" />
    </a-drawer>
  </div>
</template>

<script>
import ticket from '@/components/desk/tickets/ticket.vue'
import ticketsbyteamchart from '@/components/widgets/desk/reports/ticketsbyteamchart.vue'

export default {
    components: { ticket, ticketsbyteamchart },
    data() {
        return {
            needsRefresh: null,
            teams: [],
            widgetFetcher: { 
                start: this.$moment().startOf('month'),
                end: this.$moment().endOf('month'),
            },
            selectedMonth: 'thisMonth',
            tickets: [],
            ticketCounts: null,
            showTicketData: false,
            selectedTicket: {},
            columns: [{
              label: 'Title',
              align: 'start',
              sortable: false,
              field: 'title',
            }, {
              label: 'Assigned User',
              field: 'assignedByUser'
            }, {
              label: 'Priority',
              field: 'priority'
            }, {
              label: 'Status',
              field: 'status'
            }, {
              label: 'Created Date',
              field: 'createdDate'
            }],
        }
    },
    created() {
        this.getView()
        this.getLists()
    },
    methods: {
        getView (){ this.$http.get('/ViewAccess/ticketByTeam')
        .then(() => { 
        })
        .catch(() => { 
        })},
        showTicket(params) {
          this.selectedTicket = params.row
          this.showTicketData = true
        },
        getLists(){
          this.$http.get('/lists/Get_List/teams')
          .then((response) => {
              this.teams = response.data
          })
          .catch(() => {
          this.$message.error('There has been an error')
          })
        },
        getReport(){
          this.$http.post('/reports/Get_TicketsByTeam/', this.widgetFetcher)
          .then((response) => {
              this.tickets = response.data
          })
          .catch(() => {
            this.$message.error('There has been an error')
          })
          this.$http.post('/reports/Get_TicketsByTeamCounts/', this.widgetFetcher)
          .then((response) => {
              this.ticketCounts = response.data
          })
          .catch(() => {
            this.$message.error('There has been an error')
          })
            this.initiateRefresh()
        },
        closeTicket(){
          this.selectedTicket = null
          this.showTicketData = false
        },
        setMonth(s){
            if (s === 'thisMonth') {
                this.widgetFetcher.start = this.$moment().startOf('month'),
                this.widgetFetcher.end = this.$moment().endOf('month')
            } else if (s === 'lastMonth') {
                this.widgetFetcher.start = this.$moment().subtract(1, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment().subtract(1, 'months').endOf('month')
            } else if (s === 'monthBeforeLast') {
                this.widgetFetcher.start = this.$moment().subtract(2, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment().subtract(2, 'months').endOf('month')
            } else if (s === 'thisYear') {
                this.widgetFetcher.start = this.$moment().startOf('year')
            }
            this.getReport()
        },
        initiateRefresh(){
            if (this.needsRefresh === true) {
                this.needsRefresh = false
            } else {
                this.needsRefresh = true
            }
        }
    }
}
</script>

<style scoped>
  .stat{
    height: 165px
  }
</style>